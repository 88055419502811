import { getEmployeDocument, getShortlinkDocument } from "../database";
import { mapDataToLinkTree as mapDataToLinkTree, mapDataToShortlink } from "../mapping/LinkTreesMapper";

export const getLinkTree = async (term: string) => {
    const { id, data } = await getEmployeDocument('/linktree', term);
    if (data == null) {
        throw new Error('box not found');
    }
    return mapDataToLinkTree(id, data);
}

export const getShortlink = async (term: string) => {
    const data = await getShortlinkDocument('/shortlinks', term);
    if (data == null) {
        throw new Error('box not found');
    }
    return mapDataToShortlink(data);
}