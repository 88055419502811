import { LinkTree, LinkType, Shortlink } from "../types/types";

const mapStringToLink = (linkString: string): LinkType => {
    switch (linkString) {
        case 'contact':
            return LinkType.contact;
        case 'email':
            return LinkType.email;
        case 'linkedin':
            return LinkType.linkedin;
        case 'instagram':
            return LinkType.instagram;
        default:
            return LinkType.other;
    }
}

const mapDataToLink = (linkObject: any) => linkObject && {
    type: mapStringToLink(linkObject.type),
    url: linkObject.url,
    name: linkObject.name
}

export const mapDataToLinkTree = (id: string, obj: any): LinkTree => {
    if (obj) {
        return obj && {
            id: id,
            name: obj.name,
            position: obj.position,
            image: obj.image,
            links: obj.links.map((l: any) => mapDataToLink(l)),
        }
    } else {
        throw new Error(`impossible to map data to LinkTree (${obj})`);
    }
}

export const mapDataToShortlink = (obj: any): Shortlink => {
    if (obj) {
        return obj && {
            url: obj.url,
        }
    } else {
        throw new Error(`impossible to map data to Shortlink (${obj})`);
    }
}