import { createMuiTheme } from '@material-ui/core';
import React, { useLayoutEffect, useState } from 'react';
import { ThemeProvider as ThemeProviderBase } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';

// Colors
const colors = {
    background: '#003A70',
    foreground: 'linear-gradient(296.93deg, #E9E9E9, #F3F3F3)',
    foreground1: '#E9E9E9',
    foreground2: '#F3F3F3',
    overlayer: 'rgba(39, 40, 72, 0.34)',
    blueBlur: 'background: rgba(0, 0, 20, 0.54); backdrop-filter: blur(32px)',
    darkBlur: 'background: rgba(0, 0, 0, 0.4); backdrop-filter: blur(64px)',
    text: {
        light: {
            title: '#1E1E22',
            button: '#686870',
            body: '#45464F',
            info: '#686870',
        },
        dark: {
            title: '#FFFFFF',
            button: '#C5C6C8',
            body: '#F1F1F1',
            info: '#C5C6C8',
        },
    },
}

// Fonts
const fonts = {
    title: 'font-family: Juana',
    body: 'font-family: Inter',
}

// Shadows
const shadowColor = 'rgba(0, 0, 0, 0.05)';
const focusedShadowColor = 'rgba(0, 0, 0, 0.07)';
const desktopShadow = '8px 4px 64px';
const focusedDesktopShadow = '16px 8px 76px';
const mobileShadow = '8px 4px 64px';
const focusedMobileShadow = '16px 8px 76px';

// Themes
const desktopTheme = {
    // colors
    color: colors,
    // shadows
    shadow: {
        light: `box-shadow: ${desktopShadow} ${shadowColor}`,
        focused: `box-shadow: ${focusedDesktopShadow} ${focusedShadowColor}`,
    },
    // Border Radius
    borderRadius: {
        regular: '8px',
        rounded: '100%',
    },
    // fonts
    fontType: fonts,
    fontStyle: {
        header: `font-size:24px; font-weight: 700; line-height: 130%`,
        button: `font-size:17px; font-weight: 500; line-height: 140%`,
        body: `font-size:16px; font-weight: 500; line-height: 140%`,
        info: `font-size:13px; font-weight: 500; line-height: 130%`,
    },
    // spacings
    spacing: {
        micro: '4px',
        tiny: '7px',
        small: '12px',
        body: '16px',
        buttonText: '20px',
        regular: '20px',
        medium: '26px',
        large: '36px',
        huge: '48px',
        extreme: '128px',
    },
    sectionWidth: '340px',
    profilePictureDiameter: '128px',
};

const mobileTheme = {
    // colors
    color: colors,
    // shadows
    shadow: {
        light: `box-shadow: ${mobileShadow} ${shadowColor}`,
        focused: `box-shadow: ${focusedMobileShadow} ${focusedShadowColor}`,
    },
    // Border Radius
    borderRadius: {
        regular: '6px',
        rounded: '100%',
    },
    // fonts
    fontType: fonts,
    fontStyle: {
        header: `font-size:22px; font-weight: 700; line-height: 130%`,
        button: `font-size:16px; font-weight: 500; line-height: 140%`,
        body: `font-size:16px; font-weight: 500; line-height: 140%`,
        info: `font-size:13px; font-weight: 500; line-height: 130%`,
    },
    // spacings
    spacing: {
        micro: '4px',
        tiny: '6px',
        small: '10px',
        body: '13px',
        buttonText: '18px',
        regular: '17px',
        medium: '22px',
        large: '28px',
        huge: '36px',
        extreme: '68px',
    },
    sectionWidth: '250px',
    profilePictureDiameter: '112px',
};

const MuiTheme = createMuiTheme({
    palette: {
      primary: {
        main: colors.foreground2,
        light: colors.foreground2,
        dark: colors.foreground2,
        contrastText: colors.foreground2,
      },
      type: 'dark'
    },
  });

const ThemeProvider: React.FC = ({ children }): JSX.Element => {

    const [actualTheme, setActualTheme] = useState<Object>(desktopTheme);

    useLayoutEffect(() => {
        const updateSize = () => setActualTheme(window.innerWidth > 1000 ? desktopTheme : mobileTheme);
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [])

    return (
        <ThemeProviderBase theme={actualTheme}>
            <MuiThemeProvider theme={MuiTheme}>
                {children}
            </MuiThemeProvider>
        </ThemeProviderBase>
    );
};

export default ThemeProvider;