import ThemeProvider from './utils/theme/theme';
import Button from './components/Button';
import VerticalContainer from './components/VerticalContainer';
import ProfilePicture from './components/ProfilePicture';
import Style from "styled-components";
import Label from './components/Label';
import { useLocation } from "react-router-dom";
import { ColorMode, ColorStyle, LinkTree, FontStyle, FontType } from './utils/types/types';
import { useEffect, useState } from 'react';
import { getLinkTree, getShortlink } from './utils/repository';
import './config/firebase';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import TextField from '@material-ui/core/TextField';
import { Trans } from "react-i18next";
import { useHistory } from 'react-router-dom';
import firebase from 'firebase';

const App = () => {
    const analytics = firebase.analytics();

    const history = useHistory();

    const [term, setTerm] = useState<string>();
    const [error, setError] = useState<boolean>(false);
    const [linkTree, setLinkTree] = useState<LinkTree>();
    const pineurURL = "https://pineur.fr/"
    const [redirectionURL, setRedirectionURL] = useState<String>(pineurURL);
    const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);

    const location = useLocation();

    const getNormalizedString = (text: string) => text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    // Listen to url path
    useEffect(() => {
        const term = location.pathname.substring(1);
        if (term) {
            setTerm(term)
        } else {
            setTerm('default')
        }
    }, [location]);

    // Listen to term, if default, redirect to createl.com, else look into database
    useEffect(() => {
        if (term && term !== 'redirection' && location.pathname.substring(1) === term) {
            getShortlink(getNormalizedString(term))
                .then(s => {
                    analytics.logEvent('redirection', { location: s.url });
                    analytics.logEvent('content_found', { term: term });
                    setRedirectionURL(s.url)
                    setShouldRedirect(true)
                })
                .catch((e) => {
                    console.log('e', e);
                    getLinkTree(getNormalizedString(term))
                        .then(e => {
                            analytics.logEvent('content_found', { term: term });
                            setLinkTree(e)}
                        )
                        .catch(() => {
                            analytics.logEvent('no_content', { term: term });
                            setError(true)
                        });
                });
        } else if (term === 'default') {
            getLinkTree(getNormalizedString(term))
                .then(e => {
                    analytics.logEvent('content_found', { term: term });
                    setLinkTree(e)
                }
                )
                .catch(() => {
                    analytics.logEvent('no_content', { term: term });
                    setError(true)
                });
            // analytics.logEvent('redirection', { location: 'pineur.fr' });
            // setRedirectionURL(pineurURL)
            // setShouldRedirect(true)
        }
        // eslint-disable-next-line
    }, [term, location]);

    // Listen to employee and update page title
    useEffect(() => {
        document.title = linkTree?.name || "BDG";
        analytics.logEvent('display_page', { employeeId: linkTree?.id });
        // eslint-disable-next-line
    }, [linkTree]); 

    // Listen to error and send it to analytics
    // eslint-disable-next-line
    useEffect(() => analytics.logEvent('error', { error }), [error]);

    const getSkeletonView = () =>
        <>
            <Label text='loading.description' margin="12px 0px 12px 0px" type={FontType.body} fontStyle={FontStyle.body} color={ColorStyle.info} mode={ColorMode.dark} align='center' />  
            <SkeletonContainer>
                <Skeleton height={28} width={224} />
            </SkeletonContainer>
            <Skeleton height={24} width={128} />
        </>

    const getEmployeView = () =>
        <>
            <ProfilePicture iconPath={linkTree?.image ?? ''} />
            {console.log(linkTree)}
            <Label text={linkTree?.name || ""} margin="0px 0px 4px 0px" type={FontType.body} fontStyle={FontStyle.header} color={ColorStyle.title} mode={ColorMode.dark} align='center' />
            <Label text={linkTree?.position || "-"} margin="0px 0px 64px 0px" type={FontType.body} fontStyle={FontStyle.button} color={ColorStyle.button} mode={ColorMode.dark} align='center' />
            {linkTree?.links.map(l => <Button key={l.type} type={l.type} url={l.url} name={l.name} linkTreeId={linkTree.id} />)}
        </>

    const getErrorView = () =>
        <>
            <Label text='profile.not_found' margin="12px 0px 32px 0px" type={FontType.body} fontStyle={FontStyle.body} color={ColorStyle.body} mode={ColorMode.dark} align='center'>...<strong>{{term}}</strong>...</Label>
            <TextField id="outlined-basic" fullWidth onKeyPress={(e: any) => {
                if (e.code === "Enter") {
                    history.push(`/${getNormalizedString(e.target.value)}`);
                    setError(false);
                }
            }} label={<Trans i18nKey='profile.search.textfield.button'></Trans>} variant="outlined" />
        </>

    const getRedirectionView = () =>
        <>
            <meta http-equiv="refresh" content={"0; URL="+redirectionURL} />
        </>

    const getContent = () => {
        if (shouldRedirect) {
            return getRedirectionView()
        } else if (linkTree) {
            return getEmployeView()
        } else if (error) {
            return getErrorView()
        } else {
            return getSkeletonView()
        }
    }

    return (
        <ThemeProvider>
            <SkeletonTheme color="#3F4049" highlightColor="#63646B">
                <VerticalContainer>
                    <a href={pineurURL} target="_blank" rel="noopener noreferrer" onClick={() => analytics.logEvent('redirection', { location: 'hec.edu' })}>
                    </a>
                    {getContent()}
                </VerticalContainer>
            </SkeletonTheme>
        </ThemeProvider>
    );
}

const Logo = Style.img<{}>`
  height: 24px;
  margin: 0 0 24px 0; 
`;

const SkeletonContainer = Style.div`
  margin: 18px 0;
`;

export default App;
