import 'firebase/firestore' // <- needed if using firestore
import firebase from 'firebase/app'

export const getEmployeDocument = async (collection: string, term: string) => {
    try {
        const docs = (await firebase.firestore().collection(collection).where('terms', 'array-contains', term).get()).docs;
        const doc1 = docs[0]
        const data = doc1.data()
        // console.log(`document fetched`, data);
        return {
            id: doc1.id,
            data: data
        };
    } catch(e){
        console.log(e)
    }
    return {
        id: "",
        data: {}
    }
}

// export const getShortlinkDocument = async (collection: string, term: string) => {
//     const doc = (await firebase.firestore().collection(collection).doc(term).get());
//     const data = doc.data();
//     return data
// }


export const getShortlinkDocument = async (collection: string, term: string) => {
    const docRef = firebase.firestore().collection(collection).doc(term);
    const doc = await docRef.get();
    const data = doc.data();

    // Log the hit in the document if it exists
    if (data) {
        await docRef.update({
            hits: firebase.firestore.FieldValue.increment(1),
            lastHit: firebase.firestore.Timestamp.now()
        });
        if (data.hits && data.lastHit) {
            // Not the first time
           
        } else {
            // It is the first time
            await docRef.set({
                hits: 1,
                lastHit: firebase.firestore.Timestamp.now()
            }, { merge: true });
        }
    }
    
    // console.log(`document fetched`, data);
    return data
}