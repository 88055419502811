export enum ColorMode {
    light = 'light',
    dark = 'dark',
}

export enum ColorStyle {
    title = 'title',
    body = 'body',
    info = 'info',
    button = 'button',
}

export enum FontType {
    title = 'title',
    body = 'body',
}

export enum FontStyle {
    header = 'header',
    button = 'button',
    body = 'body',
    info = 'info',
}

export enum LinkType {
    linkedin,
    contact,
    email,
    instagram,
    other
}

type Link = {
    type: LinkType,
    url: string,
    name?: string
}

export type LinkTree = {
    id: string,
    name: string,
    position: string,
    image: string,
    links: Link[],
}

export type Shortlink = {
    url: string,
}